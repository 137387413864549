import $ from 'jquery';
import { Datepicker } from 'vanillajs-datepicker';
import 'eonasdan-bootstrap-datetimepicker';
import { OBSERVER } from '../formbuilder';
import AddressGoogle from './addressGoogle';
import isMobile from './helper';
import TomSelect from '../../../../../../themes/mont-royal/assets/node_modules/tom-select/dist/js/tom-select.base.js';

// Ajoute un attribut ayant la valeur du champ
export function inputsAndTextareaLabel(classeContainerFormBuilder) {
  // eslint-disable-next-line max-len
  const elementsString = `${classeContainerFormBuilder} input[type="text"],${classeContainerFormBuilder} input[type="tel"],${classeContainerFormBuilder} input[type="email"],${classeContainerFormBuilder} textarea`;
  const formElements = document.querySelectorAll(elementsString);
  const formElementsLength = formElements.length;
  let i;

  for (i = 0; i < formElementsLength; i += 1) {
    formElements[i].parentNode.parentNode.dataset[`${formElements[i].tagName.toLowerCase()}value`] = formElements[i].value;
  }

  const setValue = (e) => {
    e.target.parentNode.parentNode.dataset[`${e.target.tagName.toLowerCase()}value`] = e.target.value;
  };

  OBSERVER.add({
    name: 'inputsAndTextareaLabel',
    event: 'input',
    target: elementsString,
    function: setValue,
  });

  OBSERVER.on('inputsAndTextareaLabel');
}

// Modification de la hauteur d'un textarea selon son contenu
export function textareaHeight(classeContainerFormBuilder) {
  const onInput = (e) => {
    e.currentTarget.style.height = '5px';
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 43 ? 43 : e.currentTarget.scrollHeight}px`;
  };

  OBSERVER.add({
    name: 'textareaHeight',
    event: 'input',
    target: `${classeContainerFormBuilder} textarea`,
    function: onInput,
  });

  OBSERVER.on('textareaHeight');
}

export function tomSelect(classeContainerFormBuilder) {
  if (!isMobile()) {
    const themePath = window.config.theme_path;
    const selects = document.querySelectorAll(`${classeContainerFormBuilder} select:not(.tom-select-excluded)`);

    selects.forEach((select) => {
      const tSelect = new TomSelect(select, {
        controlInput: `<button>${select.getAttribute('placeholder')}</button>`,
      });
      tSelect.settings.placeholder = "New placeholder";

      // Ajouter un svg dans le label du custom select
      const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
      useElem.setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'xlink:href',
        `/themes/${themePath}/assets/medias/images/icons/symbols-box-fill.svg#ico-chevron`,
      );
      svgElem.appendChild(useElem);
      tSelect.control.appendChild(svgElem);
    });
  }
}

// Initialisation du champ de timepicker
export function timepicker(classeContainerFormBuilder) {
  if (!isMobile()) {
    $(`${classeContainerFormBuilder} .c-input-field.c-input-field--timepicker input`).datetimepicker({
      format: 'HH:mm',
      icons: {
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
      },
    });
  }
}

// Initialisation du champ de calendrier
export default function calendarInput(classeContainerFormBuilder) {
    if (!isMobile()) {
      const datepickersInput = document.querySelectorAll(`${classeContainerFormBuilder} .js-input-field-calendar .js-input-field-input`);
      const datepickersDropdown = [];

      Datepicker.locales.fr = {
        days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
        monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
        today: "Aujourd'hui",
        monthsTitle: 'Mois',
        clear: 'Effacer',
        weekStart: 0,
        format: 'dd/mm/yyyy',
      };

      for (let i = 0; i < datepickersInput.length; i += 1) {
        datepickersDropdown.push(
          new Datepicker(datepickersInput[i], {
            language: document.querySelector('html').lang,
            prevArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols-box-stroke.svg#ico-chevron-2"></use></svg>`,
            nextArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols-box-stroke.svg#ico-chevron-2"></use></svg>`,
            format: 'dd MM yyyy',
            todayHighlight: true,
            autohide: true,
            beforeShowDay: (date) => ({
              content: `
                <span class="day-square-container-parent">
                  <span class="day-square-container">
                    <span class="day-square">${date.getDate()}</span>
                  </span>
                </span>`,
            }),
          }),
        );
      }
    } else {
      document.querySelectorAll('.js-input-field-calendar .js-input-field-input').forEach(
        (element) => element.setAttribute('type', 'date'),
      );
    }
  }

// Google Autocomplete pour le champ Adresse
function addressAutocomplete(classeContainerFormBuilder) {
  const adressFields = $(`${classeContainerFormBuilder} .localisation_field`).get();
  adressFields.forEach((adressField) => {
    // eslint-disable-next-line no-new
    new AddressGoogle(adressField);
  });
}

// Le package à appliquer pour les formulaires
export function formsPackage(classeContainerFormBuilder) {
  inputsAndTextareaLabel(classeContainerFormBuilder);
  textareaHeight(classeContainerFormBuilder);
  tomSelect(classeContainerFormBuilder);
  timepicker(classeContainerFormBuilder);
  calendarInput(classeContainerFormBuilder);
  addressAutocomplete(classeContainerFormBuilder);
}
