import Observer from './vendor/observer.min';
import { formsPackage } from './functions/form';
import masksPackage from './functions/masks';
import setConditions from './functions/conditions';
import limitedCheckboxLists from './functions/limitedCheckboxLists';
import { repeaterButtons, prepareTomSelects } from './functions/repeaters';

export const OBSERVER = Observer.getInstance();

addEventListener('DOMContentLoaded', () => {
  initFormBuilder();
})


export const initFormBuilder = (outsideRepeater = true, classeContainerFormBuilder = '.dynamicForm') => {
  if (outsideRepeater) {
    prepareTomSelects();
    recaptchaFormsCallback();
  }

  formsPackage(classeContainerFormBuilder);
  masksPackage();
  initFileUpload(classeContainerFormBuilder);
  setConditions();
  limitedCheckboxLists();
  repeaterButtons();
};

window.initFormBuilder = initFormBuilder;
