import { OBSERVER } from '../formbuilder';
/* global $ */

/*
 Détermine si une condition est comblé pour le type de champs checkboxlist
 */
function matchCheckboxlistCondition(values, operator, conditionValues) {
  let hide = true;
  const conditionArray = JSON.parse(conditionValues);

  let matchCount = 0;
  values.forEach((value) => {
    if (conditionArray.includes(value)) {
      matchCount += 1;
    }
  });

  switch (operator) {
    case 'only_one':
      if (matchCount === 1 && values.length === 1) {
        hide = false;
      }
      break;
    case 'at_least_one':
      if (matchCount >= 1) {
        hide = false;
      }
      break;
    case 'at_least_all':
      if (matchCount === conditionArray.length) {
        hide = false;
      }
      break;
    case 'none':
      if (matchCount === 0) {
        hide = false;
      }
      break;
    default: // exact
      if ((matchCount === conditionArray.length) && (matchCount === values.length)) {
        hide = false;
      }
  }

  return hide;
}

/*
 Détermine si une condition est comblé
 */
function matchCondition(conditionId, target, operator, conditionValue) {
  let hide = true;

  // On va chercher le type de champs
  const type = target.getAttribute(`data-condition-field-type_${conditionId}`);
  switch (type) {
    case 'dropdown':
      const select = $(target).find('select')[0]; // eslint-disable-line no-case-declarations
      if ((operator === 'equal' && (select.value === conditionValue))
        || (operator === 'not_equal' && (select.value !== conditionValue))) {
        hide = false;
      }
      break;
    case 'radiolist':
      const radioInputs = $(target).find('input'); // eslint-disable-line no-case-declarations
      let currentValue = null; // eslint-disable-line no-case-declarations
      for (let i = 0; i < radioInputs.length; i += 1) {
        if (radioInputs[i].checked) {
          currentValue = radioInputs[i].value;
        }
      }
      if ((operator === 'equal' && (currentValue === conditionValue))
        || (operator === 'not_equal' && (currentValue !== conditionValue))) {
        hide = false;
      }
      break;
    case 'checkboxlist':
      const checkboxInputs = $(target).find('input'); // eslint-disable-line no-case-declarations
      let currentValues = []; // eslint-disable-line no-case-declarations, prefer-const
      for (let i = 0; i < checkboxInputs.length; i += 1) {
        if (checkboxInputs[i].checked) {
          currentValues.push(checkboxInputs[i].value);
        }
      }
      hide = matchCheckboxlistCondition(currentValues, operator, conditionValue);
      break;
    default: // Checkbox
      const checkbox = $(target).find('input')[0]; // eslint-disable-line no-case-declarations
      if ((operator === 'equal' && checkbox.checked)
        || (operator === 'not_equal' && (checkbox.checked === false))) {
        hide = false;
      }
  }

  return hide;
}

/*
 Cache les champs conditionnels qui ne devrait pas être affichés selon les valeurs de départ des champs sources
 */
 function initHiddenFields() {
  // On prend tous les champs qui sont la source d'une condition
  const sourceFields = document.querySelectorAll('[data-condition-ids]');
  for (let i = 0; i < sourceFields.length; i += 1) {
    const sourceConditionIds = sourceFields[i].dataset.conditionIds;
    const sourceConditionArray = sourceConditionIds.split(',');

    for (let k = 0; k < sourceConditionArray.length; k += 1) {
      const value = sourceFields[i].getAttribute(`data-condition-value_${sourceConditionArray[k]}`);
      const operator = sourceFields[i].getAttribute(`data-condition-operator_${sourceConditionArray[k]}`);

      // On ne veux pas que les conditions des champs invisibles s'appliquent
      let hide = true;
      if (sourceFields[i].style.display === '' || sourceFields[i].style.display === 'block') {
        hide = matchCondition(sourceConditionArray[k], sourceFields[i], operator, value);
      }

      if (hide) {
        const fieldsToHide = document.querySelectorAll(`[data-parent-condition-id="${sourceConditionArray[k]}"]`);
        for (let j = 0; j < fieldsToHide.length; j += 1) {
          fieldsToHide[j].style.display = 'none';
        }
      }
    }
  }
}

/*
 Permet aux champs sources de type 'Select' de trigger un changement
 */
function changeSelect(select) {
  const DROPDOWN = select;

  function triggerChange() {
    const e = new Event('change', { bubbles: true });
    DROPDOWN.dispatchEvent(e);
  }

  if (DROPDOWN) {
    const OPTIONS = DROPDOWN.nextElementSibling.querySelectorAll('.dropdown-option');

    OBSERVER.add({
      name: 'triggerChange',
      event: 'click',
      target: OPTIONS,
      function: triggerChange,
    });
    OBSERVER.on('triggerChange');
  }
}
function initSelects() {
  const triggers = document.querySelectorAll('[data-condition-ids]');
  triggers.forEach((trigger) => {
    const select = $(trigger).find('select');
    if (select.length) {
      changeSelect(select[0]);
    }
  });
}

/*
 Comportement d'ajustement de la visibilité des champs
 hideAll : Paramètre qui force la disparition des champs (dans le contexte qu'un champs source de condition est caché)
 */
 function adjustFields(e, hideAll = false) {
  // On va chercher le champs (dépend si c'est l'observer ou le récursif qui fait le call)
  const field = e.currentTarget ? e.currentTarget : e;
  const fieldConditionIds = field.dataset.conditionIds;
  const fieldConditionArray = fieldConditionIds.split(',');

  for (let k = 0; k < fieldConditionArray.length; k += 1) {
    const value = field.getAttribute(`data-condition-value_${fieldConditionArray[k]}`);
    const operator = field.getAttribute(`data-condition-operator_${fieldConditionArray[k]}`);
    const hide = hideAll ? true : matchCondition(fieldConditionArray[k], field, operator, value);
    const fieldsToAdjust = document.querySelectorAll(`[data-parent-condition-id="${fieldConditionArray[k]}"]`);
    for (let i = 0; i < fieldsToAdjust.length; i += 1) {
      if (hide) {
        $(fieldsToAdjust[i]).hide();
      } else {
        $(fieldsToAdjust[i]).show();
      }

      // Si le champs affecté est la source d'une condition
      if (fieldsToAdjust[i].dataset.conditionIds) {
        adjustFields(fieldsToAdjust[i], hide);
      }
    }
  }
}

/*
 Pour mettre des observers sur les champs sources
 */
function setObservers() {
  OBSERVER.add({
    name: 'conditions',
    event: 'change',
    target: '[data-condition-ids]',
    function: adjustFields,
  });

  OBSERVER.on('conditions');
}

export default function setConditions() {
  initHiddenFields();
  initSelects();
  setObservers();
}
